html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
  overflow: hidden;
}

.view-container {
  padding-top: 10px;
  padding-left: 24px;
  padding-right: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menuitem-active {
  color: black;
}

.timecards .timecards-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.timecards .timecards-header .timecards-header-payperiod h1 {
  margin-top: 0;
  font-size: 18px;
}

.timecards .timecards-header .timecards-header-payperiod {
  width: 100%;
  display: flex;
  align-items: center;
}

.timecards .timecards-header .timecards-header-payperiod {
  width: 100%;
}

.timecards .timecards-header .timecards-header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  white-space: nowrap;
}

.timecards .timecards-header .timecards-header-actions .add-timecard {
  width: 161px;
}

.timecards .timecards-header .timecards-header-actions .add-timecard-recruiter {
  display: none;
}

.timecards .MuiTab-root {
  min-width: inherit;
}

.timecards .section-title {
  font-weight: 400;
}

.timecards .MuiTab-wrapper {
  text-transform: none;
}

.timecards .timecard-tile {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: solid 1px #e7e7e7;
  background-color: #ffffff;
  padding-left: 25px;
  padding-top: 10px;
  padding-right: 25px;
  padding-bottom: 10px;
  margin-bottom: 11px;
  cursor: pointer;
}

.timecards .timecard-tile:hover {
  border: solid 1px #c3c3c3;
}

.timecards .timecard-tile.duplicate {
  background-color: #eaedf5;
}

.timecards .timecard-tile.selected {
  border: solid 1px #7a7a7a;
}

.timecards .timecard-tile.selected:hover {
  border: solid 1px #525252;
}

.timecard-tile-fileicons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.timecard-tile-fileicons .MuiAvatar-root {
  width: 38px;
  height: 38px;
}

.timecards .timecard-tile.active {
  border: solid 2px #666666;
}

.timecards .timecard-tile-error-avatar {
  background-color: #f44336;
}

.timecards .timecard-tile-fullname {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.timecards .timecard-tile-location {
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #7f7f7f;
}

.timecards .timecard-tab {
  padding: 0;
  margin-top: 30px;
}

.timecards .MuiTab-textColorPrimary.Mui-selected {
  color: #000;
}

.timecards .MuiTab-textColorPrimary {
  color: #000;
}

.timecards .PrivateTabIndicator-colorPrimary-91 {
  background-color: #000;
}

.timecards .timecards-due-date {
  font-size: 14px;
  color: #7f7f7f;
}

div.timecards {
  border-right: 2px solid #e7e7e7;
  background-color: #fafafa;
  padding-left: 20px;
  padding-top: 10px;
  width: fit-content;
}

#root .timecards-list {
  padding-right: 16px;
  padding-bottom: 70px;
}

.timecards .timecards-pay-period {
  font-size: 14px;
  color: #7f7f7f;
  margin-bottom: 18px;
}

.timecards-home-title {
  margin-bottom: 0;
  font-weight: 200;
}

.timecards .timecard-tab-title {
  font-size: 14px;
  display: flex;
}

.timecards .timecards-home-title .timecards-home-title-date {
  font-weight: 400;
}

.timecard-tab-title-badge {
  background-color: #50478b;
  color: white;
  border-radius: 99px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 6px;
}

.timecard-detail-container {
  width: 100%;
  min-width: 740px;
  display: flex;
  flex-direction: column;
}

.timecard-detail-topbar {
  display: flex;
  border-bottom: solid 3px #e7e7e7;
  padding-left: 40px;
  padding-top: 20px;
  padding-right: 40px;
}

.timecard-detail-topbar,
.timecard-detail-pane {
  -webkit-box-shadow: -2px 0px 4px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: -2px 0px 4px 0px rgba(230, 230, 230, 1);
  box-shadow: -2px 0px 4px 0px rgba(230, 230, 230, 1);
}

.traveler-history {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 50;
}

.traveler-history .timecard-detail-topbar {
  box-shadow: none;
  border-bottom: none;
}

.timecard-detail-topbar-details {
  padding-left: 25px;
  color: #333333;
  overflow: hidden;
  width: 100%;
}

.timecard-detail-topbar-details > p {
  margin: 0px;
  white-space: nowrap;
}

.timecard-detail-topbar-details-fullname {
  font-size: 26px;
  color: #333333;
  margin: 0px;
}

.timecard-detail-topbar-details-ssn,
.timecard-detail-topbar-details-location,
.timecard-detail-topbar-details-missed-shift-data,
.timecard-detail-topbar-work-week {
  font-size: 11px;
  color: #999999;
  text-transform: uppercase;
}

.timecard-detail-topbar-icons {
  display: flex;
  padding-top: 6%;
  padding-bottom: 6%;
}

.timecard-detail-topbar-icons > div {
  margin-left: 5px;
}

#root .file-attached-indicator {
  border-radius: 20px;
  border: solid 1px #e5e5e5;
  background-color: #f3f3f3;
}

#root .file-attached-indicator.auto-attaching,
#root .file-attached-indicator.filled {
  background-color: #50478b;
  border: none;
}

#root .file-attached-indicator.digital-timecards {
  background-color: #0aa0dc;
  border: none;
}

#root .file-attached-indicator.manual {
  background-color: #ffeb3b;
  border: none;
}

#root .file-attached-indicator.spreadsheet {
  background-color: orange;
  border: none;
}

#root .file-attached-indicator.signatureDeclined {
  background-color: red;
  border: none;
}

#root .file-attached-indicator.timecardSigned {
  background-color: #0aa0dc;
  border: none;
}

#root .file-attached-indicator > i {
  font-size: 20px;
  font-weight: 100;
  color: #b8b5b5;
}

#root .file-attached-indicator.filled > i,
#root .file-attached-indicator.auto-attaching > i,
#root .file-attached-indicator.digital-timecards > i,
#root .file-attached-indicator.spreadsheet > i {
  color: white;
}

#root .file-attached-indicator.manual > i {
  color: black;
}

.forward-detail-status-icon .file-forwared-indicator > i {
  font-size: 20px;
  font-weight: 100;
  color: #b8b5b5;
}

.forward-detail-status-icon .file-forwared-indicator {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #e5e5e5;
  background-color: #f3f3f3;
}

#root .file-forwared-indicator > i {
  font-size: 20px;
  font-weight: 100;
  color: #b8b5b5;
}

#root .file-forwared-indicator {
  border-radius: 20px;
  border: solid 1px #e5e5e5;
  background-color: #f3f3f3;
}

#root .file-forwared-indicator.pending {
  background-color: #ffeb3b;
  border: none;
}

#root .file-forwared-indicator.pending > i {
  color: white;
}

.forward-detail-status-icon .file-forwared-indicator.on-time {
  background-color: #8bc34a;
  border: none;
}

.forward-detail-status-icon .file-forwared-indicator.on-time > i {
  color: white;
}

.forward-detail-status-icon .file-forwared-indicator.late {
  background-color: #f44336;
  border: none;
}

.forward-detail-status-icon .file-forwared-indicator.late > i {
  color: white;
}

#root .file-forwared-indicator.on-time {
  background-color: #8bc34a;
  border: none;
}

#root .file-forwared-indicator.on-time > i {
  color: white;
}

#root .file-forwared-indicator.late {
  background-color: #f44336;
  border: none;
}

#root .file-forwared-indicator.late > i {
  color: white;
}

.timecard-detail-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.timecard-detail-pane-question {
  height: 80%;
  align-items: center;
  justify-content: center;
  width: 55%;
  display: flex;
  flex-direction: column;
}

.timecard-detail-pane-expensesquestion > button {
  width: 100px;
}

.timecard-detail-pane-expensesquestion > button:not(:first-child) {
  margin-left: 13px;
}

.timecard-detail-pane-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.timecard-detail-pane-upload > button {
  width: 455px;
}

.timecard-detail-pane-upload > button:not(:first-child) {
  margin-top: 15px;
}

.timecard-detail-pane-timecardoptions {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.timecard-detail-pane-timecardoptions > label {
  cursor: pointer;
  /*https://stackoverflow.com/questions/880512/prevent-text-selection-after-double-click*/
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.timecard-list-loading-container,
.timecard-list-empty {
  height: calc(100vh - 190px);
}

.timecard-list-loading-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.timecard-list-empty {
  text-align: center;
  font-size: 20px;
  font-weight: 100;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.timecard-detail-pane .timecard-detail-pane-question .dropzone-container {
  height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 20px;
}

.timecard-detail-pane .timecard-detail-pane-question .dropzone-paragraph {
  font-size: 14px;
  margin: 0;
}

.timecard-detail-pane .timecard-detail-pane-question .dropzone-container svg {
  display: none;
}

.timecard-detail-pane-upload {
  width: 455px;
}

.timecard-detail-uploading-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timecard-detail-uploading-file p {
  font-size: 1.25em;
}
.job-assignment {
  max-height: 500px;
  overflow: auto;
}

.timecard-timeentry,
.timecard-readonly {
  width: 95%;
  padding: 20px;
}

.timecard-hours-entry-table input {
  text-align: right;
  font-size: 14px;
}

.timecard-hours-entry-table tr {
  height: 32px;
}

.timecard-detail-bottombar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: solid 3px #e7e7e7;
  background-color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#root .timecard-detail-bottombar-button {
  height: 30px;
  margin-right: 10px;
}

#root .referrals-referrer-send-button {
  font-size: 0.95em;
  padding: 0;
  color: #50478b;
}

#root .timecard-detail-bottombar-save-button {
  height: 30px;
}

#root .timecard-detail-bottombar-button:last-child {
  margin-right: 0;
}

#root .timecard-detail-bottombar-save-additional-note-button {
  height: 30px;
  margin-right: 10px;
  display: none;
}

#root .timecard-detail-bottombar-return-button {
  height: 30px;
  margin-right: 10px;
}

#root .timecard-detail-bottombar-approve-button {
  height: 30px;
  margin-right: 10px;
}

.timecard-detail-bottombar-icon {
  font-size: 14px;
  margin-right: 5px;
}

body ._loading_overlay_overlay {
  background: rgba(255, 255, 255, 0.7);
}

#root .timecard-detail-pane .timecard-hours-entry-table .MuiTableCell-sizeSmall {
  padding-top: 0px;
  padding-bottom: 0px;
}

.loading-timecard-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.notes-section {
  margin-top: 20px;
  padding: 19px 24px 24px;
}

.jobassignment-notes-section {
  background-color: "#50478b";
  color: "white";
  border-radius: "10px";
  font-size: "10px";
  padding-left: "6px";
  padding-right: "3px";
  margin-left: "6px";
  min-width: "20px";
  display: "flex";
  height: "100%";
  flex-direction: "column";
}

.note-characters-left {
  font-size: 12px;
  text-align: right;
  margin-top: 4px;
}

.add-note-button-container {
  display: flex;
  justify-content: flex-end;
}

.note-container .MuiListItemText-secondary {
  font-size: 0.75rem;
}

.note-container .MuiListItemText-primary {
  word-break: break-all;
  white-space: pre-wrap;
}

.notes-section .note-section-textarea-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.timecard-readonly .timecard-readonly-paper {
  margin-bottom: 14px;
}

.timecard-expense-syncing input {
  color: #8bc34a;
}

.timecard-expense-syncing.MuiInput-underline {
  border-bottom: 1px solid rgba(0, 162, 78, 0.42);
}

.timecard-expense-deduction input {
  color: #f44336;
}

.timecard-expense-highlight {
  background-color: #ffeb3b;
}
.billing-item-highlight {
  background-color: #ffeb3b;
}
.payroll-item-highlight {
  background-color: #ffeb3b;
}

#root .timecards .returned-badge {
  position: relative;
  padding: 4px;
  font-size: 10px;
  color: white;
  background-color: #50478b;
}

#root .add-timecard-fab {
  position: relative;
  float: right;
  bottom: 90px;
  left: 20px;
}

.new-timecard-dialog .MuiDialog-container > div.MuiDialog-paper {
  min-height: 450px;
  min-width: 450px;
}

.new-timecard-dialog .new-timecard-no-assignments {
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.new-timecard-dialog .new-timecard-no-assignments > svg {
  margin-right: 10px;
}

.new-timecard-dialog .new-timecard-assignments-list-container {
  margin-top: 25px;
}

.new-timecard-dialog .assignment-list-item {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.new-timecard-dialog .assignment-list-item > div:first-child {
  display: flex;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: solid 1px #e7e7e7;
  cursor: pointer;
}

.new-timecard-dialog .assignment-list-item > div:hover {
  border: solid 1px #c3c3c3;
}

.new-timecard-dialog .assignment-list-item.selected > div {
  border: solid 1px #7a7a7a;
}

.new-timecard-dialog .assignment-list-item.selected > div:hover {
  border: solid 1px #525252;
}
.new-timecard-dialog .assignments-list-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.candidate-selector-root {
  flex-grow: 1;
}

.candidate-selector-container {
  position: "relative";
}

.candidate-selector-container-open {
  position: "absolute";
  z-index: 1;
  margin-top: 4px;
  left: 0;
  right: 0;
}

.candidate-selector-suggestion {
  display: "block";
}

.candidate-selector-suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.DayPickerInput .DayPickerInput-Month {
  border-collapse: separate;
}
.DayPickerInput .DayPickerInput-WeekNumber {
  outline: none;
}
.DayPickerInput .DayPickerInput-Day {
  outline: none;
  border: 1px solid transparent;
}
.DayPickerInput .DayPickerInput-Day--hoverRange {
  background-color: #efefef !important;
}
.DayPickerInput .DayPickerInput-Day--selectedRange {
  background-color: #fff7ba !important;
  border-top-color: #ffeb3b;
  border-bottom-color: #ffeb3b;
  border-left-color: #fff7ba;
  border-right-color: #fff7ba;
}
.DayPickerInput .DayPickerInput-Day--selectedRangeStart {
  background-color: #ffeb3b !important;
  border-left: 1px solid #ffeb3b;
}
.DayPickerInput .DayPickerInput-Day--selectedRangeEnd {
  background-color: #ffeb3b !important;
  border-right: 1px solid #ffeb3b;
}
.DayPickerInput
  .DayPickerInput-Day--selectedRange:not(.DayPickerInput-Day--outside).DayPickerInput-Day--selected,
.DayPickerInput-Day--hoverRange:not(.DayPickerInput-Day--outside).DayPickerInput-Day--selected {
  border-radius: 0 !important;
  color: black !important;
}
.DayPickerInput .DayPickerInput-Day--hoverRange:hover {
  border-radius: 0 !important;
}

.DayPickerInput .DayPickerInput-Overlay {
  z-index: 99;
}

.payperiod-title {
  font-weight: 200;
}

.history-table-icons-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.timecard-tile-fileicons.time-card-topbar {
  align-content: baseline;
  justify-content: flex-start;
  margin-left: 10px;
  margin-bottom: 5px;
  flex-wrap: wrap;
  max-width: 15%;
  gap: 5px;
}

span.darkHeaderText {
  color: #727272;
  margin-right: 0.3rem;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
}

.allCaps,
.allCaps * {
  text-transform: uppercase;
}
